@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import "~@nebular/theme/styles/globals";
@import "~@nebular/auth/styles/globals";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";

// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "./overrides";

$bg-color: #0a7a7e;
$white-color: #ffffff;
$primary: #0a7a7e;

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  //@include ngx-layout();
  // loading progress bar
  //@include ngx-pace-theme();

  //@include nb-overrides();

  /* Importing Bootstrap SCSS file. */
  //@import '~bootstrap/scss/bootstrap';
  body {
    font-family: "Open Sans", sans-serif;
  }
}

.homelogin nb-layout-column nb-card {
  background-color: transparent !important;
  border: none;
  height: 100% !important;
}
.homelogin .columns {
  background: url(../../../assets/images/login-bg.jpg) no-repeat;
}
.login {
  background: rgba($color: #000000, $alpha: 0.7);
  border-radius: 4px;
  padding: 10px;
}
.login label {
  color: #fff !important;
}

.loginBtn {
  background-color: $bg-color !important;
  border-color: $bg-color !important;
}
.forgot-password {
  color: $white-color !important;
}
.nb-theme-default nb-layout-header nav {
  background-color: $bg-color !important;
}
.nb-theme-default nb-sidebar {
  background-color: #f4f4f4 !important;
}
.sidebar-toggle {
  color: $white-color !important;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.container-body {
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  padding: 20px 20px 20px 20px;
}
.nb-theme-default
  nb-layout
  .layout
  .layout-container
  .content
  .columns
  nb-layout-column {
  padding: 12px;
}
.header-title {
  // display: flex;
  // justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 12px;
  margin-bottom: 12px;
  align-items: center;
}
.header-title h2 {
  color: #333333;
  font-size: 30px !important;
  margin: 0 !important;
}
.search-container {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  border: 1px solid #ddd;
  padding: 12px;
  border-top: 3px solid #0a7a7e;
  border-radius: 4px !important;
}
.search-container h3 {
  color: #333333;
  font-size: 20px !important;
  margin: 0 !important;
}
.mat-header-row {
  background-color: #fff;
}
.mat-sort-header-content,
.mat-column-actions {
  color: #3c8dca !important;
}
.btn {
  background-color: $primary !important;
  color: $white-color !important;
}
.mat-header-cell {
  color: #3c8dca !important;
}
.nb-theme-default nb-menu .menu-item {
  border-bottom: 1px solid #e3e3e3;
}
.nb-theme-default nb-menu .menu-item a.active {
  color: #fff;
  background: #0a7a7e;
}
.nb-theme-default nb-menu .menu-item a.active .menu-icon {
  color: #fff;
}
.nb-theme-default nb-menu .menu-item a:hover {
  color: #fff;
  background: #0a7a7e;
}
.nb-theme-default nb-menu .menu-item a:hover .menu-icon {
  color: #fff;
}
.nb-theme-default .menu-sidebar .scrollable {
  padding-top: 0 !important;
}
.mat-elevation-z8 {
  box-shadow: none !important;
  border: 1px solid #ddd;
}
.nb-theme-default nb-user.size-medium .user-name {
  color: #fff !important;
}
.nb-theme-default nb-user.shape-round .user-picture {
  color: #fff !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 5px !important;
  background-color: #f5f5f5 !important;
}

::-webkit-scrollbar {
  width: 12px !important;
  background-color: #555 !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: rgb(117, 117, 117) !important;
}

.nb-theme-default nb-checkbox.status-basic .text{
  color: #fff !important;
}

//For spinner
// .nb-theme-default nb-spinner.size-medium {
//   height: 15% !important;
//   z-index: 999999;
// }
